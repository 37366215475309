import React, { useState } from "react";
import {
  AppBar,
  Toolbar,
  Typography,
  IconButton,
  useTheme,
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Divider,
} from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import MenuIcon from "@mui/icons-material/Menu";
import HomeIcon from "@mui/icons-material/Home";
import PricingIcon from "@mui/icons-material/MonetizationOn";
import ContactIcon from "@mui/icons-material/ContactMail";
import FaqIcon from "@mui/icons-material/HelpOutline";
import DemoIcon from "@mui/icons-material/Slideshow";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";

const Navbar = () => {
  const theme = useTheme();
  const [drawerOpen, setDrawerOpen] = useState(false);
  const navigate = useNavigate();
  const handleDrawerOpen = () => {
    setDrawerOpen(true);
  };

  const handleDrawerClose = () => {
    setDrawerOpen(false);
  };

  const drawerItems = [
    { text: "Home", icon: <HomeIcon />, link: "/" },
    { text: "Pricing", icon: <PricingIcon />, link: "/pricing" },
    { text: "Contact", icon: <ContactIcon />, link: "/contact" },
    { text: "FAQs", icon: <FaqIcon />, link: "/faqs" },
    { text: "Demo", icon: <DemoIcon />, link: "/demo" },
  ];

  return (
    <>
      <AppBar
        position="static"
        color="default"
        style={{ backgroundColor: theme.palette.background.paper }}
      >
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            aria-label="menu"
            onClick={handleDrawerOpen}
          >
            <MenuIcon />
          </IconButton>
          <Typography
            variant="h6"
            style={{ flexGrow: 1, color: theme.palette.text.primary }}
            onClick={() => navigate("/")}
          >
            BadgeReg
          </Typography>
          {/*<Button color="inherit">Login</Button>*/}
        </Toolbar>
      </AppBar>
      <Drawer anchor="left" open={drawerOpen} onClose={handleDrawerClose}>
        <div style={{ width: 250 }}>
          <IconButton onClick={handleDrawerClose}>
            <ChevronLeftIcon />
          </IconButton>
          <Divider />
          <List>
            {drawerItems.map((item) => (
              <ListItem
                button
                key={item.text}
                onClick={handleDrawerClose}
                component={Link}
                to={item.link}
                style={{ textDecoration: "none", color: "inherit" }}
              >
                <ListItemIcon>{item.icon}</ListItemIcon>
                <ListItemText primary={item.text} />
              </ListItem>
            ))}
          </List>
        </div>
      </Drawer>
    </>
  );
};

export default Navbar;
