import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { ThemeProvider, useMediaQuery } from "@mui/material";

import Navbar from "./components/NavBar";
import HeroSection from "./components/HeroSection";
import FeaturesSection from "./components/FeaturesSection";
import Testimonials from "./components/Testemonials";
import CallToAction from "./components/CallToAction";
import Footer from "./components/Footer";
import { lightTheme, darkTheme } from "./theme";
import Pricing from "./components/Pricing";
import Demo from "./components/Demo";
import FeatureList from "./components/FeatureList";
import FAQ from "./components/FAQ";
import ContactForm from "./components/ContactForm";

const App = () => {
  const prefersDarkMode = useMediaQuery("(prefers-color-scheme: dark)");

  console.log("Dark theme preference: " + prefersDarkMode);

  return (
    <ThemeProvider theme={prefersDarkMode ? darkTheme : lightTheme}>
      <Router>
        <Navbar />
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            minHeight: "calc(100vh - 64px - 80px)",
          }}
        >
          <Routes>
            <Route
              path="/"
              element={
                <>
                  <HeroSection />
                  <FeaturesSection />
                  <Testimonials />
                  <CallToAction />
                </>
              }
            />
            <Route
              path="/pricing"
              element={
                <>
                  <Pricing />
                  <CallToAction />
                </>
              }
            />
            <Route
              path="/contact"
              element={
                <>
                  <ContactForm />
                </>
              }
            />
            <Route
              path="/faqs"
              element={
                <>
                  <FAQ />
                  <CallToAction />
                </>
              }
            />
            <Route
              path="/demo"
              element={
                <>
                  <Demo />
                  <FeatureList />
                  <CallToAction />
                </>
              }
            />
          </Routes>
        </div>
        <Footer />
      </Router>
    </ThemeProvider>
  );
};

export default App;
