import { createTheme } from "@mui/material/styles";

const lightTheme = createTheme({
  palette: {
    mode: "light",
    footer: {
      backgroundColor: "#fff",
    },
    // Define light mode colors here
  },
  // Other customizations
});

const darkTheme = createTheme({
  palette: {
    mode: "dark",
    footer: {
      backgroundColor: "#272727",
    },
    // Define dark mode colors here
  },
  // Other customizations
});

export { lightTheme, darkTheme };
