import React from "react";
import { Box, Typography, Grid, Card, useTheme } from "@mui/material";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";

const features = [
  {
    title: "Purpose-Built System",
    description:
      "Expertly designed to cater specifically to scouting events, ensuring a seamless and intuitive experience for both organizers and participants.",
  },
  {
    title: "Real-time Monitoring",
    description:
      "Track event progress and scout engagement in real-time, allowing for immediate insights and adjustments.",
  },
  {
    title: "Online Payments Integration",
    description:
      "Securely process payments with our integrated system, ensuring smooth transactions for classes, events, and more.",
  },
  {
    title: "Unlimited Class Offerings",
    description:
      "Flexibility to offer as many classes as your event requires, with no limitations on variety or frequency.",
  },
  {
    title: "Administer Scouts' Schedules",
    description:
      "Effortlessly manage and adjust schedules for each scout, ensuring a personalized and organized event experience.",
  },
  {
    title: "Adjustable Event Times",
    description:
      "Easily modify event times to accommodate changes, ensuring your event runs smoothly.",
  },
  {
    title: "Class Capacity Management",
    description:
      "Set and manage class size limits to ensure a conducive learning environment and maintain control over your event.",
  },
  {
    title: "Multiple Instances of the Same Class",
    description:
      "Offer multiple sessions of popular classes, ensuring every scout has the opportunity to participate.",
  },
  {
    title: "Users Can Update Their Classes After Registration",
    description:
      "Provide flexibility by allowing participants to modify their class choices post-registration, accommodating changing preferences or schedules.",
  },
  {
    title: "Set Registration Open & Close Dates",
    description:
      "Define the registration timeline for your event, ensuring clarity and structure for participants.",
  },
  {
    title: "Logo & URL Customization",
    description:
      "Brand your event with custom logos and URLs, enhancing recognition and trust among participants.",
  },
  {
    title: "Custom Color Palettes",
    description:
      "Personalize your event's aesthetic with custom color schemes, aligning the look and feel with your brand or theme.",
  },
  {
    title: "Customized Email Receipts",
    description:
      "Send personalized email receipts to participants, providing them with detailed and branded transaction records.",
  },
  {
    title: "Custom FAQs & Prerequisites",
    description:
      "Tailor the information and requirements you provide, ensuring participants are well-prepared and informed before the event.",
  },
  {
    title: "Class Age Limits",
    description:
      "Set age restrictions for classes to ensure appropriate and targeted content for different scout age groups.",
  },
  {
    title: "Bring Your Own Flyer",
    description:
      "Use your custom flyers to promote your event, maintaining consistency in your event's branding and messaging.",
  },
  {
    title: "Define Your Own Terms & Conditions",
    description:
      "Customize the terms and conditions shown at checkout for your event to ensure clear communication of rules and expectations.",
  },
  {
    title: "Data Export",
    description:
      "Export your registrants and counselors as convenient CSV files, allowing for easy data management and analysis.",
  },
  {
    title: "Security and Data Privacy ",
    description:
      "We strive to ensure the highest level of security and data privacy for all of our users. Your data is yours.",
  },
  {
    title: "And Much More!",
    description:
      "We are continually adding new features and improvements to our system to ensure it meets your needs. Contact us to learn more!",
  },
];

const FeatureList = () => {
  const theme = useTheme();

  return (
    <Box p={4} sx={{ backgroundColor: theme.palette.background.default }}>
      <Typography
        variant="h4"
        gutterBottom
        align="center"
        sx={{ color: theme.palette.text.primary, fontWeight: "bold", pb: 3 }}
      >
        Feature Highlights
      </Typography>
      <Grid container spacing={2} justifyContent="center">
        {features.map((feature, index) => (
          <Grid item xs={12} sm={6} key={index}>
            <Card sx={{ display: "flex", alignItems: "center", p: 2 }}>
              <CheckCircleOutlineIcon
                color="primary"
                sx={{ fontSize: 40, mr: 2 }}
              />
              <Box>
                <Typography variant="subtitle1" color="text.primary">
                  {feature.title}
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  {feature.description}
                </Typography>
              </Box>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default FeatureList;
