import React from "react";
import { Box, Typography, Button, useTheme } from "@mui/material";
import { useNavigate } from "react-router-dom";

const CallToAction = () => {
  const theme = useTheme();
  const navigate = useNavigate();

  return (
    <Box
      textAlign="center"
      p={4}
      style={{
        backgroundColor: theme.palette.background.paper,
        flexGrow: 1, // Make the Box grow to occupy all available space
        display: "flex", // Make this a flex container
        flexDirection: "column", // Arrange children in a column
        justifyContent: "center", // Center children vertically
        alignItems: "center", // Center children horizontally
        minHeight: "200px", // Minimum height for the Box
      }}
    >
      <Typography variant="h4" style={{ color: theme.palette.text.primary }}>
        Ready to Start?
      </Typography>
      <Typography
        variant="subtitle1"
        style={{ color: theme.palette.text.primary }}
      >
        Join the troops using BadgeReg today.
      </Typography>
      <Button
        variant="contained"
        color="primary"
        size="large"
        style={{ marginTop: 20 }}
        onClick={() => navigate("/contact")}
      >
        Contact Us Now
      </Button>
    </Box>
  );
};

export default CallToAction;
