import React from "react";
import {
  Box,
  Typography,
  Paper,
  useTheme,
  Button,
  Grid,
  Tooltip,
  useMediaQuery,
} from "@mui/material";
import LaunchIcon from "@mui/icons-material/Launch";

const Demo = () => {
  const theme = useTheme();
  const demoUrl = "https://democollege.crew324.com/";
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <Box p={4} sx={{ backgroundColor: theme.palette.background.default }}>
      <Box display="flex" justifyContent="center" alignItems="center" mb={3}>
        <Tooltip title="Programming" arrow placement="top">
          <span>
            <img src="badges/Programming.svg" alt="Programming" height={70} />
          </span>
        </Tooltip>
      </Box>
      <Typography
        variant="h4"
        gutterBottom
        align="center"
        sx={{ color: theme.palette.text.primary, fontWeight: "bold", pb: 3 }}
      >
        Explore Our Interactive Demo
      </Typography>
      <Paper
        elevation={3}
        sx={{
          padding: 3,
          marginBottom: 3,
          backgroundColor: theme.palette.background.paper,
        }}
      >
        <Grid container spacing={3} justifyContent="center" alignItems="center">
          <Grid item xs={12} md={8}>
            <Typography
              variant="subtitle1"
              align="center"
              sx={{ color: theme.palette.text.primary, pb: 2 }}
            >
              Get a first-hand experience of how BadgeReg works.
            </Typography>
            {/* Conditional rendering based on screen size */}
            {isMobile ? (
              // Render a button for mobile devices
              <Button
                variant="contained"
                color="primary"
                startIcon={<LaunchIcon />}
                fullWidth
                sx={{ mb: 2 }}
                onClick={() => window.open(demoUrl, "_blank")}
              >
                Open Demo in New Tab
              </Button>
            ) : (
              // Render the iframe for non-mobile devices
              <Box
                sx={{
                  border: "1px solid",
                  borderColor: theme.palette.divider,
                  height: "750px", // Adjust height as needed
                  mb: 2,
                }}
              >
                <iframe
                  src={demoUrl}
                  title="BadgeReg Demo"
                  width="100%"
                  height="100%"
                  style={{ border: "none" }}
                />
              </Box>
            )}

            {/* This button will be displayed for non-mobile devices */}
            {!isMobile && (
              <Button
                variant="contained"
                color="primary"
                startIcon={<LaunchIcon />}
                sx={{ mb: 2 }}
                onClick={() => window.open(demoUrl, "_blank")}
              >
                Open Demo in New Tab
              </Button>
            )}
          </Grid>
        </Grid>
      </Paper>
    </Box>
  );
};

export default Demo;
