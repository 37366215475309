import React, { useState } from "react";
import {
  Box,
  Container,
  Typography,
  TextField,
  Button,
  useTheme,
  CircularProgress,
  Snackbar,
  Stack,
  Tooltip,
} from "@mui/material";
import MuiAlert from "@mui/material/Alert";
import ky from "ky";

const ContactForm = () => {
  const theme = useTheme();
  const [contactDetails, setContactDetails] = useState({
    user_name: "",
    user_email: "",
    message: "",
  });
  const [isLoading, setIsLoading] = useState(false);
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: "",
    severity: "success",
  });

  const handleChange = (e) => {
    setContactDetails({ ...contactDetails, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const { user_name, user_email, message } = contactDetails;

    // Format the message you want to send to Slack
    const slackMessage = {
      text: `New Inquiry from BadgeReg!\n*Name:* ${user_name}\n*Email:* ${user_email}\n*Message:* ${message}`,
    };

    setIsLoading(true);

    // Send the message to Slack using ky
    ky.post(
      "https://hooks.slack.com/services/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",
      {
        json: slackMessage,
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
      },
    )
      .then(() => {
        setSnackbar({
          open: true,
          message: "Your message has been sent to our Slack channel!",
          severity: "success",
        });
      })
      .catch((error) => {
        console.error("Failed to send message to Slack", error);
        setSnackbar({
          open: true,
          message: "Failed to send your message. Please try again.",
          severity: "error",
        });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handleCloseSnackbar = () => {
    setSnackbar({ ...snackbar, open: false });
  };

  return (
    <Box
      p={4}
      style={{
        backgroundColor: theme.palette.background.default,
        flexGrow: 1,
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        minHeight: "200px",
      }}
    >
      <Container>
        <Stack direction="row" spacing={2}>
          <Tooltip title="Communications" arrow placement="top">
            <span>
              <img
                src="badges/Communications.svg"
                alt="Communications"
                height={50}
              />
            </span>
          </Tooltip>
          <Typography
            variant="h4"
            gutterBottom
            sx={{
              color: theme.palette.text.primary,
              fontWeight: "bold",
              pb: 3,
              pt: 1,
            }}
          >
            Contact Us
          </Typography>
        </Stack>
        <Typography
          variant="subtitle1"
          gutterBottom
          sx={{ color: theme.palette.text.secondary, pb: 0 }}
        >
          Reach out to us to get started or if you have any questions!
        </Typography>
        <Typography
          variant="subtitle1"
          gutterBottom
          sx={{ color: theme.palette.text.secondary, pb: 2 }}
        >
          Feel free to send up any feature requests or feedback as well :)
        </Typography>
        <form onSubmit={handleSubmit}>
          <TextField
            label="Name"
            name="user_name"
            variant="outlined"
            fullWidth
            margin="normal"
            required
            onChange={handleChange}
            value={contactDetails.user_name}
          />
          <TextField
            label="Email"
            name="user_email"
            variant="outlined"
            fullWidth
            margin="normal"
            required
            onChange={handleChange}
            value={contactDetails.user_email}
          />
          <TextField
            label="Message"
            name="message"
            variant="outlined"
            fullWidth
            margin="normal"
            required
            multiline
            rows={4}
            onChange={handleChange}
            value={contactDetails.message}
          />
          <Button
            type="submit"
            variant="contained"
            color="primary"
            sx={{ mt: 3 }}
            disabled={isLoading}
          >
            {isLoading ? <CircularProgress size={24} /> : "Send"}
          </Button>
        </form>
      </Container>

      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
      >
        <MuiAlert
          elevation={6}
          variant="filled"
          onClose={handleCloseSnackbar}
          severity={snackbar.severity}
        >
          {snackbar.message}
        </MuiAlert>
      </Snackbar>
    </Box>
  );
};

export default ContactForm;
