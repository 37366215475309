import React, { useEffect, useRef } from "react";
import { Box, Typography, Button, useTheme } from "@mui/material";
import { gsap } from "gsap";
import { CSSPlugin } from "gsap/CSSPlugin";
import { useNavigate } from "react-router-dom";

// Force CSSPlugin to not get dropped during build
gsap.registerPlugin(CSSPlugin);

const HeroSection = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const svgContainerRef = useRef(null);
  // Determine background color based on theme mode
  const backgroundColor =
    theme.palette.mode === "dark"
      ? "rgba(0, 0, 0, 0.7)"
      : "rgba(255, 255, 255, 0.7)";

  // Function to shuffle an array (Fisher-Yates shuffle)
  const shuffleArray = (array) => {
    for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [array[i], array[j]] = [array[j], array[i]];
    }
    return array;
  };

  useEffect(() => {
    // Assuming you have an array of SVG URLs
    const svgURLs = [
      "badges/AmericanBusiness.svg",
      "badges/AmericanCultures.svg",
      "badges/AmericanHeritage.svg",
      "badges/AmericanLabor.svg",
      "badges/AnimalScience.svg",
      "badges/Animation.svg",
      "badges/Archaeology.svg",
      "badges/Archery.svg",
      "badges/Architecture.svg",
      "badges/Art.svg",
      "badges/Astronomy.svg",
      "badges/Athletics.svg",
      "badges/AutomotiveMaintenance.svg",
      "badges/Aviation.svg",
      "badges/Backpacking.svg",
      "badges/Basketry.svg",
      "badges/BirdStudy.svg",
      "badges/Bugling.svg",
      "badges/Camping.svg",
      "badges/Canoeing.svg",
      "badges/Chemistry.svg",
      "badges/Chess.svg",
      "badges/CitizenshipInSociety.svg",
      "badges/CitizenshipInTheCommunity.svg",
      "badges/CitizenshipInTheNation.svg",
      "badges/CitizenshipInTheWorld.svg",
      "badges/Climbing.svg",
      "badges/CoinCollecting.svg",
      "badges/Collections.svg",
      "badges/Communications.svg",
      "badges/CompositeMaterials.svg",
      "badges/Cooking.svg",
      "badges/CrimePrevention.svg",
      "badges/Cycling.svg",
      "badges/Dentistry.svg",
      "badges/DigitalTechnology.svg",
      "badges/DisabilitiesAwareness.svg",
      "badges/DogCare.svg",
      "badges/Drafting.svg",
      "badges/Electricity.svg",
      "badges/Electronics.svg",
      "badges/EmergencyPreparedness.svg",
      "badges/Energy.svg",
      "badges/Engineering.svg",
      "badges/Entrepreneurship.svg",
      "badges/EnvironmentalScience.svg",
      "badges/Exploration.svg",
      "badges/FamilyLife.svg",
      "badges/FarmMechanics.svg",
      "badges/Fingerprinting.svg",
      "badges/FireSafety.svg",
      "badges/FirstAid.svg",
      "badges/FishAndWildlifeManagement.svg",
      "badges/Fishing.svg",
      "badges/FlyFishing.svg",
      "badges/Forestry.svg",
      "badges/GameDesign.svg",
      "badges/Gardening.svg",
      "badges/Genealogy.svg",
      "badges/Geocaching.svg",
      "badges/Geology.svg",
      "badges/Golf.svg",
      "badges/GraphicArts.svg",
      "badges/HealthcareProfessions.svg",
      "badges/Hiking.svg",
      "badges/HomeRepairs.svg",
      "badges/Horsemanship.svg",
      "badges/IndianLore.svg",
      "badges/InsectStudy.svg",
      "badges/Inventing.svg",
      "badges/Journalism.svg",
      "badges/Kayaking.svg",
      "badges/LandscapeArchitecture.svg",
      "badges/Law.svg",
      "badges/Leatherwork.svg",
      "badges/Lifesaving.svg",
      "badges/MammalStudy.svg",
      "badges/Metalwork.svg",
      "badges/MiningInSociety.svg",
      "badges/ModelDesignAndBuilding.svg",
      "badges/Motorboating.svg",
      "badges/Moviemaking.svg",
      "badges/Music.svg",
      "badges/Nature.svg",
      "badges/NuclearScience.svg",
      "badges/Oceanography.svg",
      "badges/Orienteering.svg",
      "badges/Painting.svg",
      "badges/PersonalFitness.svg",
      "badges/PersonalManagement.svg",
      "badges/Pets.svg",
      "badges/Photography.svg",
      "badges/Pioneering.svg",
      "badges/PlantScience.svg",
      "badges/Plumbing.svg",
      "badges/Pottery.svg",
      "badges/Programming.svg",
      "badges/PublicHealth.svg",
      "badges/PublicSpeaking.svg",
      "badges/PulpAndPaper.svg",
      "badges/Radio.svg",
      "badges/Railroading.svg",
      "badges/Reading.svg",
      "badges/ReptileAndAmphibianStudy.svg",
      "badges/RifleShooting.svg",
      "badges/Robotics.svg",
      "badges/Rowing.svg",
      "badges/Safety.svg",
      "badges/Salesmanship.svg",
      "badges/Scholarship.svg",
      "badges/ScoutingHeritage.svg",
      "badges/ScubaDiving.svg",
      "badges/Sculpture.svg",
      "badges/SearchAndRescue.svg",
      "badges/ShotgunShooting.svg",
      "badges/SignsSignalsAndCodes.svg",
      "badges/Skating.svg",
      "badges/SmallBoatSailing.svg",
      "badges/SnowSports.svg",
      "badges/SoilAndWaterConservation.svg",
      "badges/SpaceExploration.svg",
      "badges/Sports.svg",
      "badges/StampCollecting.svg",
      "badges/Surveying.svg",
      "badges/Sustainability.svg",
      "badges/Swimming.svg",
      "badges/Textile.svg",
      "badges/Theater.svg",
      "badges/TrafficSafety.svg",
      "badges/TruckTransportation.svg",
      "badges/VeterinaryMedicine.svg",
      "badges/WaterSports.svg",
      "badges/Weather.svg",
      "badges/Welding.svg",
      "badges/Whitewater.svg",
      "badges/WildernessSurvival.svg",
      "badges/WoodCarving.svg",
      "badges/Woodwork.svg",
    ];
    const shuffledSvgURLs = shuffleArray([...svgURLs]);

    const positionSVGs = () => {
      const svgContainer = svgContainerRef.current;
      const { width: containerWidth, height: containerHeight } =
        svgContainer.getBoundingClientRect();

      // Clear previous SVGs
      while (svgContainer.firstChild) {
        svgContainer.removeChild(svgContainer.firstChild);
      }

      // Add SVGs to the container
      const svgs = shuffledSvgURLs.map((url) => {
        const img = new Image();
        img.src = url;
        img.className = "badge-svg"; // for styling purposes
        svgContainer.appendChild(img);
        return img;
      });

      // Dynamically calculate the size of the SVGs to show more badges
      const svgSize = Math.sqrt(
        (containerWidth * containerHeight) / (svgs.length * 1.2),
      );
      const svgMargin = 10; // Margin between SVGs
      const totalSvgSize = svgSize + svgMargin;

      // Calculate the number of columns, accounting for margin
      const columns = Math.max(1, Math.floor(containerWidth / totalSvgSize));

      svgs.forEach((svg, index) => {
        const colNum = index % columns;
        const rowNum = Math.floor(index / columns);

        const posX = colNum * totalSvgSize + (colNum === 0 ? svgMargin : 0); // Add padding for leftmost badges
        const posY = rowNum * totalSvgSize + (rowNum === 0 ? svgMargin : 0); // Add padding for topmost badges

        // Set the size and position directly
        svg.style.width = `${svgSize}px`;
        svg.style.height = `${svgSize}px`;
        svg.style.left = `${posX}px`;
        svg.style.top = `${posY}px`;

        // 3D Spiral Animation
        gsap.fromTo(
          svg,
          {
            rotationX: 360 * Math.random(),
            rotationY: 360 * Math.random(),
            rotationZ: 360 * Math.random(),
            x: posX + 100 * Math.cos((2 * Math.PI * index) / svgs.length),
            y: posY + 100 * Math.sin((2 * Math.PI * index) / svgs.length),
            z: -500 + 1000 * Math.random(), // Random depth
            opacity: 0,
          },
          {
            rotationX: 0,
            rotationY: 0,
            rotationZ: 0,
            x: posX,
            y: posY,
            z: 0,
            opacity: 1,
            duration: 2, // animation duration
            ease: "power3.inOut",
            delay: index * 0.02, // Staggered delay for each badge
          },
        );
      });
    };

    // Initial positioning
    positionSVGs();

    // Handle window resize
    const handleResize = () => {
      positionSVGs();
    };

    window.addEventListener("resize", handleResize);

    // Cleanup the event listener
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <Box
      textAlign="center"
      p={4}
      style={{
        minHeight: "70vh",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: theme.palette.background.default,
        position: "relative",
        overflow: "hidden",
      }}
    >
      <div
        ref={svgContainerRef}
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          display: "flex",
          flexWrap: "wrap",
          alignContent: "flex-start",
        }}
      />
      <Box
        style={{
          position: "relative",
          zIndex: 1,
          padding: "20px",
          backgroundColor,
          borderRadius: "10px",
          maxWidth: "600px",
        }}
      >
        <Typography variant="h2" style={{ color: theme.palette.text.primary }}>
          Merit Badge Fundraising, Simplified
        </Typography>
        <Typography
          variant="subtitle1"
          style={{ color: theme.palette.text.secondary, marginBottom: "20px" }}
        >
          Efficiently organize and manage merit badge fundraisers for your scout
          troop!
        </Typography>
        <Button
          variant="contained"
          color="primary"
          size="large"
          style={{ maxWidth: "300px", width: "100%" }}
          onClick={() => {
            navigate("/demo");
          }}
        >
          Check out our Demo
        </Button>
      </Box>
    </Box>
  );
};

export default HeroSection;
