import React from "react";
import {
  Box,
  Container,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  useTheme,
  Stack,
  Tooltip,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

const faqs = [
  {
    question: "How do I get started?",
    answer:
      "You can by contacting us using the contact form on our website. We will get back to you as soon as possible.",
  },
  {
    question: "What payment methods are accepted?",
    answer:
      "We accept online payments through PayPal. All transactions are sent to BadgeReg via Paypal and are dispersed to the event organizer after the fundraiser is complete.",
  },
  {
    question: "Why does this service cost money?",
    answer:
      "BadgeReg charges a small fee to cover the cost of maintaining the platform and providing support to event organizers. This fee is included in the registration cost paid by each scout. A setup fee of $250 is charged to the event organizer in order to hold accountability.",
  },
  {
    question: "What if I need to cancel my event?",
    answer:
      "The $250 setup fee is non-refundable. If you need to cancel your event, please contact us as soon as possible to discuss your options. Keep in mind that PayPal fees are non-refundable and will be deducted from your refund.",
  },
  {
    question: "Can I use my own Domain and E-Mail Address?",
    answer:
      "At this time, BadgeReg does not support custom domains or email addresses. You will be issued a domain ex: your-event.badgereg.com. You can setup your custom domain to redirect to your BadgeReg domain. Emails will be sent from receipts@badgereg.com",
  },
  {
    question: "Can I export reports about my fundraiser?",
    answer:
      "Yes, you can export reports about your fundraiser. You can export reports about your fundraiser from the admin dashboard within your domain.",
  },
];

export default function FAQ() {
  const theme = useTheme();

  return (
    <Box
      sx={{
        flexDirection: "column",
        backgroundColor: "background.default",
      }}
    >
      <Container>
        <Stack direction="row" spacing={2} pt={3}>
          <Tooltip title="Digital Technology" arrow placement="top">
            <span>
              <img
                src="badges/DigitalTechnology.svg"
                alt="Digital Technology"
                height={50}
              />
            </span>
          </Tooltip>
          <Typography
            variant="h4"
            sx={{
              color: theme.palette.text.primary,
              pb: 3,
              pt: 1,
              fontWeight: "bold",
            }}
          >
            FAQs
          </Typography>
        </Stack>
        {faqs.map((faq, index) => (
          <Accordion
            key={index}
            sx={{ mt: 2, backgroundColor: "background.paper" }}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls={`panel${index}a-content`}
              id={`panel${index}a-header`}
            >
              <Typography>{faq.question}</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>{faq.answer}</Typography>
            </AccordionDetails>
          </Accordion>
        ))}
      </Container>
    </Box>
  );
}
