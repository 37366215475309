import React from "react";
import { Box, Typography, Grid, useTheme } from "@mui/material";

const FeaturesSection = () => {
  const theme = useTheme(); // Use the theme for styling

  return (
    <Box p={4} style={{ backgroundColor: theme.palette.background.paper }}>
      <Typography
        variant="h4"
        gutterBottom
        style={{ color: theme.palette.text.primary }}
      >
        Key Features
      </Typography>
      <Grid container spacing={2}>
        {/* Feature 1: Event Management */}
        <Grid item xs={12} sm={6} md={4}>
          <Typography
            variant="h6"
            style={{ color: theme.palette.text.secondary }}
          >
            Event Management
          </Typography>
          <Typography style={{ color: theme.palette.text.primary }}>
            Simplify the process of creating and managing merit badge events
            with our comprehensive scheduling and administration tool.
          </Typography>
        </Grid>
        {/* Feature 2: Badge Tracking */}
        <Grid item xs={12} sm={6} md={4}>
          <Typography
            variant="h6"
            style={{ color: theme.palette.text.secondary }}
          >
            Easy to Use
          </Typography>
          <Typography style={{ color: theme.palette.text.primary }}>
            Our system was designed to be easy to use. It is built to work well
            for both mobile and desktop users.
          </Typography>
        </Grid>
        {/* Feature 3: Reporting and Analytics */}
        <Grid item xs={12} sm={6} md={4}>
          <Typography
            variant="h6"
            style={{ color: theme.palette.text.secondary }}
          >
            Reporting & Analytics
          </Typography>
          <Typography style={{ color: theme.palette.text.primary }}>
            Gain insights with detailed reports and analytics about your
            registrations, profit, and counselors.
          </Typography>
        </Grid>
        {/* Add more features as needed */}
      </Grid>
    </Box>
  );
};

export default FeaturesSection;
