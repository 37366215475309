import React from "react";
import { Box, Typography, Paper, useTheme } from "@mui/material";

const testimonials = [
  {
    name: "Jessica Musson",
    title: "Associate Advisor of Crew 324",
    message:
      "BadgeReg has made it so much easier to manage our crew's merit badge colleges. We love it!",
  },
  {
    name: "Crystal Carr",
    title: "Event Coordinator at Troop 959",
    message:
      "BadgeReg has been a game changer for our troop. We can't imagine going back to the old way of doing things!",
  },
  // ... add more testimonials here
];

const Testimonials = () => {
  const theme = useTheme(); // Use the theme for styling

  return (
    <Box p={4} style={{ backgroundColor: theme.palette.background.paper }}>
      <Typography
        variant="h4"
        gutterBottom
        style={{ color: theme.palette.text.primary }}
      >
        Testimonials
      </Typography>
      {testimonials.map((testimonial, index) => (
        <Paper
          key={index}
          elevation={3}
          style={{
            padding: 16,
            marginBottom: 16,
            backgroundColor: theme.palette.background.default,
          }}
        >
          <Typography
            variant="h6"
            style={{ color: theme.palette.text.secondary }}
          >
            {testimonial.name}, {testimonial.title}
          </Typography>
          <Typography style={{ color: theme.palette.text.primary }}>
            "{testimonial.message}"
          </Typography>
        </Paper>
      ))}
    </Box>
  );
};

export default Testimonials;
