import React, { useEffect, useRef, useState } from "react";
import {
  Box,
  Container,
  Grid,
  Typography,
  Link,
  Tooltip,
  useTheme,
} from "@mui/material";
import ky from "ky";

const Footer = () => {
  const theme = useTheme(); // Use the theme for styling
  const [apiVersion, setApiVersion] = useState("");
  const appVersion = window._env_.REACT_APP_VERSION;
  const footerRef = useRef(null);

  useEffect(() => {
    if (!footerRef?.current?.clientHeight) {
      return;
    }
  }, [footerRef?.current?.clientHeight]);

  useEffect(() => {
    ky.get(window._env_.REACT_APP_API_URL + "/actuator/info", {
      timeout: window._env_.REACT_APP_TIMEOUT,
    })
      .json()
      .then((response) => {
        setApiVersion(response.app.version);
      })
      .catch((e) => {
        setApiVersion("unknown");
        console.error(e);
      });
  }, []);

  return (
    <Box
      ref={footerRef}
      sx={{
        width: "100%",
        height: "auto",
        backgroundColor: theme.palette.footer.backgroundColor, // Use custom color
        paddingTop: "1rem",
        paddingBottom: "1rem",
        textAlign: "center",
        color: theme.palette.primary.contrastText,
      }}
    >
      <Container maxWidth="lg">
        <Grid container direction="column" alignItems="center">
          <Grid item xs={12}>
            <Tooltip title="Jessie helped too :p" arrow>
              <Typography
                color="text.primary"
                variant="subtitle1"
                align="center"
              >
                Copyright{" "}
                <Link
                  href="https://byerline.me"
                  target="_blank"
                  rel="noopener noreferrer"
                  color="inherit"
                >
                  Jacob Byerline
                </Link>{" "}
                © {new Date().getFullYear()}
              </Typography>
            </Tooltip>
            {appVersion && (
              <Typography
                color="text.primary"
                variant="caption"
                align="center"
                sx={{ display: "grid" }}
              >
                UI: v{appVersion}{" "}
                {!["", "unknown"].includes(apiVersion) && "API: v" + apiVersion}
              </Typography>
            )}
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default Footer;
