import React from "react";
import {
  Box,
  Typography,
  Paper,
  useTheme,
  Button,
  Tooltip,
  Grid,
  IconButton,
} from "@mui/material";
import CoffeeIcon from "@mui/icons-material/LocalCafe";
import FeatureIcon from "@mui/icons-material/StarBorder";
import InfoIcon from "@mui/icons-material/Info";
import PayPalIcon from "@mui/icons-material/Payment";
import { useNavigate } from "react-router-dom";

const Pricing = () => {
  const theme = useTheme();
  const navigate = useNavigate();

  return (
    <Box p={4} style={{ backgroundColor: theme.palette.background.default }}>
      <Box display="flex" justifyContent="center" alignItems="center" mb={3}>
        <Tooltip title="Personal Management" arrow placement="top">
          <span>
            <img
              src="badges/PersonalManagement.svg"
              alt="Personal Management"
              height={70}
            />
          </span>
        </Tooltip>
      </Box>
      <Typography
        variant="h4"
        gutterBottom
        align="center"
        sx={{ color: theme.palette.text.primary, fontWeight: "bold", pb: 3 }}
      >
        Transparent & Affordable Pricing for Your Fundraisers
      </Typography>
      <Paper
        elevation={3}
        style={{
          padding: 16,
          marginBottom: 16,
          backgroundColor: theme.palette.background.paper,
        }}
      >
        <Grid container spacing={3}>
          <Grid item xs={12} md={6}>
            <Typography
              variant="h5"
              style={{ color: theme.palette.primary.main, marginBottom: 8 }}
            >
              Event Setup Fee
            </Typography>
            <Typography
              style={{ color: theme.palette.text.primary, marginBottom: 16 }}
            >
              $250 per event
              <Tooltip
                title="Covers initial setup and support for your fundraising event."
                arrow
              >
                <IconButton
                  size="small"
                  style={{ marginLeft: 4, verticalAlign: "middle" }}
                >
                  <InfoIcon color="action" />
                </IconButton>
              </Tooltip>
            </Typography>

            <Typography
              variant="h5"
              style={{ color: theme.palette.primary.main, marginBottom: 8 }}
            >
              Per Scout
            </Typography>
            <Typography
              style={{ color: theme.palette.text.primary, marginBottom: 16 }}
            >
              $2.50 per registered scout
              <Tooltip
                title="Affordable price per scout to keep your fundraiser cost-effective."
                arrow
              >
                <IconButton
                  size="small"
                  style={{ marginLeft: 4, verticalAlign: "middle" }}
                >
                  <InfoIcon color="action" />
                </IconButton>
              </Tooltip>
            </Typography>

            <Typography
              style={{ color: theme.palette.text.primary, marginBottom: 16 }}
            >
              All payments are securely processed via{" "}
              <PayPalIcon
                style={{ color: "#0070BA", verticalAlign: "middle" }}
              />{" "}
              PayPal and include a PayPal fee.
            </Typography>

            <Typography
              style={{ color: theme.palette.primary.main, marginBottom: 16 }}
            >
              Most fundraisers raise upwards of <strong>$10,000</strong>, making
              BadgeReg a great investment!
            </Typography>
          </Grid>

          <Grid item xs={12} md={6}>
            <Typography
              variant="h5"
              style={{ color: theme.palette.primary.main, marginBottom: 8 }}
            >
              Why BadgeReg?
            </Typography>
            <Typography
              style={{ color: theme.palette.text.primary, marginBottom: 16 }}
            >
              BadgeReg is built with love by volunteers dedicated to making
              scouting fundraisers seamless and more enjoyable. We're not here
              for profit; we're here to support the community!
            </Typography>

            <Typography
              variant="h5"
              style={{ color: theme.palette.primary.main, marginBottom: 8 }}
            >
              Support Our Mission
            </Typography>
            <Button
              variant="contained"
              startIcon={<CoffeeIcon />}
              style={{ marginRight: 8, marginBottom: 8 }}
              onClick={() => {
                window.open("https://www.buymeacoffee.com/jbyerline", "_blank");
              }}
            >
              Buy me a hot coco
            </Button>
            <Button
              variant="contained"
              startIcon={<FeatureIcon />}
              style={{ marginBottom: 8 }}
              onClick={() => {
                navigate("/contact");
              }}
            >
              Sponsor a feature
            </Button>
            <Typography style={{ color: theme.palette.text.secondary }}>
              Every contribution helps us keep BadgeReg running smoothly and
              developing new features!
            </Typography>
          </Grid>
        </Grid>
      </Paper>
    </Box>
  );
};

export default Pricing;
